<template>
  <vs-card>
    <div slot="header">
      <feather-icon icon="ChevronLeftIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer" @click="$router.push('/admin/cms-pages')"></feather-icon>
      <vs-label style="font-weight: bold">Page List</vs-label>
    </div>
    <div style="padding-left: 20px;">
    <h4 class="mb-6 mt-2">Page Details</h4>
    <vs-form>
      <vs-label class="label mt-2">Page Title</vs-label>
      <vs-input class="col-sm-3 mb-6" v-model="pages.title"></vs-input>
    </vs-form>
    <vs-label class="label">Page Content</vs-label>
    <quillEditor :pages="pages"></quillEditor><br>
    <div slot="footer" class="mb-12 mt-12" v-if="check_has_permission('updatePageManagement')">
      <vs-row>
        <vs-button @click="savePageDetails" align="left">Save Details</vs-button>
      </vs-row>
    </div>
    </div>
  </vs-card>
</template>

<script>

  import { mapActions } from 'vuex';
  import quillEditor from "../../Editor";

  export default {
    name: 'pageViewEditDetail',
    components: {
      quillEditor
    },
    data:()=>({
      pages:{
        content:'',
        title:''
      },

    }),
    methods:{
      ...mapActions('page', ['fetchPageDetails','updatePageDetails']),
      getPageDetail(id) {
        let self = this;
        this.fetchPageDetails(id).then(res => {
          this.pages.content = res.data.data.content
          this.pages.title= res.data.data.title
        })
      },
      savePageDetails(){
        let id = this.$route.params.id
        let info = this.pages
        this.updatePageDetails({id,info}).then(res => {
          this.$vs.notify({
            title: 'Update Page Content',
            text: res.data.message,
            color: 'success'
          })
        })
      }
    },
    created() {
      let pageId = this.$route.params.id;
      this.getPageDetail(pageId);
    }
  }
</script>
<style>
  .ql-editor{
    min-height:200px;
  }
  .label {
    font-size: small;
    color: black;
  }
</style>
